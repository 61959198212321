import { formatMoney } from '~/utils/format'
import { IBundleProduct, ICurrentBundle } from './useBundle.types'

export const mountFinalPrice = (
  { price, compareAtPrice }: { price: number; compareAtPrice: number },
  isTradeCustomer = false
) => {
  const isSale = compareAtPrice > 0 && price !== compareAtPrice
  const isTrade = !isSale && isTradeCustomer

  const formattedPrice = formatMoney(price, 'USD', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })

  const tradePrice = isTrade
    ? formatMoney(price * 0.8, 'USD', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
    : null

  const compareAtPriceFormatted = compareAtPrice
    ? formatMoney(compareAtPrice, 'USD', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
    : null

  return {
    price,
    isTrade,
    isSale,
    visible: true,
    compareAtPriceFormatted,
    compareAtPrice: compareAtPrice,
    tradePriceFormatted: tradePrice,
    tradePrice: isTrade ? price * 0.8 : price,
    priceFormatted: formattedPrice,
    isTradeAccountLabelVisible: isTrade,
  }
}

export const parserCurrent = (bundle: IBundleProduct, isTradeCustomer = false): ICurrentBundle => {
  const products = bundle?.items

  const preOrderMessage =
    products
      .filter(product => {
        return product?.variants?.some(variant => {
          const metafield = variant?.metafields?.find?.(
            field => field && field.key === 'product_preorder_message'
          )
          return metafield && metafield.value
        })
      })
      .map(product => {
        const variant = product?.variants?.find(variant => {
          const metafield = variant?.metafields?.find?.(
            field => field && field.key === 'product_preorder_message'
          )
          return metafield && metafield.value
        })
        return {
          title: product?.title,
          preOrderMessage: variant?.metafields?.find?.(
            field => field && field.key === 'product_preorder_message'
          )?.value,
        }
      }) || []

  return {
    ...bundle,
    preOrderMessage: preOrderMessage,
    products: products,
    prices: mountFinalPrice(
      products.reduce(
        (acc: { price: number; compareAtPrice: number }, item: any) => {
          // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
          return {
            price: acc.price + parseFloat(item.price.amount),
            compareAtPrice: acc?.compareAtPrice + parseFloat(item?.compareAtPrice?.amount || 0),
          }
        },
        { price: 0, compareAtPrice: 0 }
      ),
      isTradeCustomer
    ),
  }
}

export const parseStringKeys = (str: string) => {
  // eslint-disable-next-line require-unicode-regexp
  const replacedStr = str.replace(/\{\{.*?\}\}/, '')
  return replacedStr
}
