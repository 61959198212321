/** @jsx jsx **/
import { Fragment, ReactNode, useCallback, useMemo } from 'react'

import { jsx, Box, Text, Flex } from 'theme-ui'

import { space } from '~/gatsby-plugin-theme-ui'

import { Maybe, ProductItemComponentType, ProductVariantType } from '~/@types/models'

import { useCustomerContext } from '~/context/customer-context'

import CompareAtPrice from '~/components/compare-at-price'

import useMediaQuery from '~/hooks/use-media-query'

import {
  isInvalidTradeDiscountProduct,
  isValidTradeCustomerDiscount,
} from '~/utils/is-valid-trade-customer-discount'
import { formatMoney } from '~/utils/format'
import { Tag } from '~/componentsV2/tag'
import { useMountRangePrices } from './useDiscount'

interface IReturn {
  price: number
  priceFormatted: string
  priceWithoutTrade?: number
  isSale: boolean
  priceLabel: string
  compareAtPrice?: number
  compareAtPriceFormatted?: string
  finalPrice: number
  isTradeCustomer: boolean
  isTradeAccountLabelVisible: boolean
  isTradeProduct: boolean
  rangePrice?: ReactNode
  RangePrince?: ReactNode
  defaultDiscountMessage?: string
}

type ProductVariantInfoType = {
  product: ProductItemComponentType
  productVariant?: Maybe<ProductVariantType>
  isStartingAt?: boolean
  isFabric?: boolean
  long?: boolean
  quantity?: number
}

export const useVariantPrices = ({
  product,
  productVariant,
  isStartingAt = false,
  isFabric,
  quantity = 1,
  long = false,
}: ProductVariantInfoType): IReturn => {
  const {
    customerType: { trade: isTradeCustomer },
  } = useCustomerContext()

  const isMobile = useMediaQuery({
    query: breakpoints => `(max-device-width: ${breakpoints[2]})`,
  })

  const { cheapPrice, highPrice } = useMountRangePrices({
    productVariant,
    product,
    isFabric,
    isTradeAccount: isTradeCustomer,
    quantity,
    long,
  })

  const DiscountTagMessage = ({ message }: { message?: string | null }) => {
    return message ? (
      <Tag
        className="price-tag"
        bgColor="transparent"
        style={{
          padding: '0',
          minWidth: 'auto',
          // marginLeft: isMobile ? 0 : '10px',
          lineHeight: 1.5,
          marginLeft: 0,
          fontSize: isMobile ? '8px' : 'inherit',
        }}
      >
        {message}
      </Tag>
    ) : null
  }

  const tradeLabelCustom = (isStartingAt: boolean) =>
    isTradeCustomer ? (
      <Flex
        as={Text}
        sx={{
          flexDirection: `column`,
        }}
      >
        <CompareAtPrice
          price={cheapPrice.priceFormatted}
          sxProps={{
            fontSize: ['inherit', null, 'inherit'],
            mb: [16],
            color: 'muted',
            mr: space.xxs,
          }}
        />
        <Text
          as="span"
          sx={{
            fontSize: ['18px'],
            lineHeight: [1.5],
            textTransform: 'uppercase',
            color: '#595858',
          }}
        >
          Trade Price: {isStartingAt && 'Starting at'}{' '}
          {isFabric
            ? `$${cheapPrice.compareAtPriceFormatted}`
            : formatMoney(cheapPrice.compareAtPrice)}
          {isFabric ? '/YARD' : ''}
        </Text>
      </Flex>
    ) : (
      <Box as="span">
        {isStartingAt && 'Starting at'} {cheapPrice.priceFormatted}
      </Box>
    )

  const priceLabel = tradeLabelCustom(isStartingAt)
  const PriceText = ({ children, strikePrice }: { strikePrice?: boolean; children: ReactNode }) => (
    <Text
      color="txt"
      data-comp="price-text"
      sx={{
        fontSize: ['10px', null, 'inherit'],
        textDecoration: strikePrice ? 'line-through' : 'none',
        color: strikePrice ? 'grey' : 'txt',
        lineHeight: 1.5,
      }}
    >
      {children}
    </Text>
  )

  const PriceBox = ({
    highPrice,
    cheapPrice,
    children,
    strikePrice,
  }: {
    highPrice?: string
    cheapPrice: string
    children?: ReactNode
    strikePrice?: boolean
  }) => {
    return (
      <Flex
        data-comp="price-box"
        sx={{
          flexDirection: ['column', null, 'row'],
          alignItems: ['flex-start', null, 'center'],
          justifyContent: 'flex-start',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <PriceText strikePrice={strikePrice}>{cheapPrice}</PriceText>
          {Number(highPrice?.replace(/[^0-9.]/g, '')) > 0 && (
            <PriceText strikePrice={strikePrice}>
              <Fragment>
                {' — '}
                {highPrice}
              </Fragment>
            </PriceText>
          )}
        </Box>
        {children}
      </Flex>
    )
  }

  const createRangePrice = () => {
    return (
      <Flex
        data-comp="createRangePrice-price"
        className="range-price"
        sx={{
          flexDirection: 'column',
          '.price-tag': {
            color: '#2b2b2b !important',
            fontWeight: '400 !important',
            cursor: 'default !important',
            textAlign: 'start',
          },
        }}
      >
        {cheapPrice && highPrice ? (
          <Fragment>
            <Flex sx={{ alignItems: 'center', gap: space.xs }}>
              <PriceBox
                cheapPrice={cheapPrice.priceFormatted}
                highPrice={highPrice.priceFormatted}
              ></PriceBox>
              <Box sx={{ display: ['none', null, 'flex'], alignItems: 'center' }}>
                <DiscountTagMessage message={cheapPrice.discountMessage} />
              </Box>
            </Flex>
            {cheapPrice.compareAtPriceFormatted && highPrice.compareAtPriceFormatted ? (
              <PriceBox
                cheapPrice={cheapPrice.compareAtPriceFormatted}
                highPrice={highPrice.compareAtPriceFormatted}
                strikePrice
              ></PriceBox>
            ) : null}
          </Fragment>
        ) : (
          <Flex sx={{ alignItems: 'center', gap: space.xs }}>
            <PriceBox cheapPrice={cheapPrice.priceFormatted}></PriceBox>
            {cheapPrice.compareAtPriceFormatted ? (
              <PriceBox cheapPrice={cheapPrice.compareAtPriceFormatted} strikePrice></PriceBox>
            ) : null}

            <Box sx={{ display: ['none', null, 'flex'], alignItems: 'center' }}>
              <DiscountTagMessage message={cheapPrice.discountMessage} />
            </Box>
          </Flex>
        )}
        <Box sx={{ display: ['flex', null, 'none'], alignItems: 'center' }}>
          <DiscountTagMessage message={cheapPrice.discountMessage} />
        </Box>
      </Flex>
    )
  }

  return {
    price: cheapPrice.price,
    rangePrice: createRangePrice(),
    priceFormatted: cheapPrice.priceFormatted,
    priceWithoutTrade: cheapPrice.price,
    isSale: cheapPrice?.discountType === 'sales',
    discountType: cheapPrice?.discountType,
    priceLabel,
    compareAtPrice: cheapPrice.compareAtPrice,
    defaultDiscountMessage: cheapPrice.discountMessage,
    compareAtPriceFormatted: cheapPrice.compareAtPriceFormatted,
    finalPrice: cheapPrice.compareAtPrice ? cheapPrice.compareAtPrice : cheapPrice.price,
    isTradeCustomer,
    isTradeAccountLabelVisible: cheapPrice?.discountType === 'trade',
    isTradeProduct: isInvalidTradeDiscountProduct(product?.productType),
  }
}
